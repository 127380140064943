<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  created() {
    const basic = localStorage.getItem("token");
    if (!basic) {
      this.$router.push({ name: "AuthPage" });
    }
  },
};
</script>

<style lang="scss">
body {
  margin: 0;
}
#app {
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  line-height: 22px;
  color: #1e293b;
}
</style>

import { createApp } from "vue";
import apolloProvider from "./apollo";
import App from "./App.vue";
import router from "./router";
import "@/assets/scss/main.scss";
import Maska from "maska";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import "floating-vue/dist/style.css";
import { VTooltip } from "floating-vue";

const app = createApp(App);
app.directive("clickoutside", {
  beforeMount(el, binding) {
    el.clickOutsideEvent = evt => {
      evt.stopPropagation();
      if (!(el === evt.target || el.contains(evt.target))) {
        binding.value(evt, el);
      }
    };
    window.requestAnimationFrame(() => {
      document.addEventListener("click", el.clickOutsideEvent);
    });
  },
  unmounted(el) {
    document.removeEventListener("click", el.clickOutsideEvent);
  },
});
app.directive("tooltip", VTooltip);
app.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 10,
  newestOnTop: true,
});
app.use(Maska);
app.use(router);
app.use(apolloProvider);
app.mount("#app");

import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/auth",
    component: () => import("@/views/AuthPage.vue"),
    name: "AuthPage",
  },
  {
    path: "/",
    component: () => import("@/layouts/MainLayout.vue"),
    name: "MainLayout",
    redirect: { name: "EmployeesList" },
    children: [
      {
        path: "employee",
        component: () => import("@/layouts/EmployeeLayout.vue"),
        name: "EmployeeLayout",
        children: [
          {
            path: "",
            component: () => import("@/views/EmployeesList.vue"),
            name: "EmployeesList",
          },

          {
            path: "add-employee",
            component: () => import("@/views/EmployeePage.vue"),
            name: "AddEmployeePage",
          },
          {
            path: ":id",
            component: () => import("@/views/EmployeePage.vue"),
            name: "EditEmployeePage",
          },
        ],
      },
      {
        path: "template",
        component: () => import("@/layouts/TemplateLayout.vue"),
        name: "TemplateLayout",
        children: [
          {
            path: "",
            component: () => import("@/views/TemplateList.vue"),
            name: "TemplateList",
          },
          {
            path: "add-template",
            component: () => import("@/views/TemplatePage.vue"),
            name: "AddTemplatePage",
          },
          {
            path: ":id",
            component: () => import("@/views/TemplatePage.vue"),
            name: "EditTemplatePage",
          },
        ],
      },
      {
        path: "change-password",
        component: () => import("@/views/PasswordFormPage.vue"),
        name: "PasswordForm",
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
